import {
  EnvironmentConfiguration,
  environmentDev,
} from 'polly-commons';

export const environment: EnvironmentConfiguration = {
  ...environmentDev,
  staticAssets: 'static/manage/',
};

export const dashboardListBaseUrl: string = 'https://dev-dashboards.elucidata.io';
